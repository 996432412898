import React, { useEffect, useState } from "react";

import AuthUserContext from "components/Session/AuthUserContext";
import withAuthorization from "components/Session/withAuthorization";

import { auth } from "api/auth";

import {
  Container,
  Header,
  Next,
} from "components/Onboarding/OnboardingComponents";

import {
  pushOnboarding,
  getUserOnboardingData,
  addOnboardingCount,
} from "api/db";

function ExperienceOnboarding() {
  const [interest, setInterest] = useState(null);

  useEffect(() => {
    getUserOnboardingData(auth.currentUser.uid).then((snapshot) => {
      setInterest(snapshot.val().interest);
    });
  }, []);

  return (
    <AuthUserContext.Consumer>
      {(authUser) => (
        <Container>
          <Header />
          <section>
            <span>3/3</span>
            <div>
              <h1 className="text-2xl font-bold text-gray-900">Have you coded before?</h1>
              <Next
                paintDrip
                to={"/onboarding/project-start"}
                hex="#438cee"
                duration={1}
                onClick={() => {
                  let projectRecommendation =
                    interest == "Web Development" || interest == "Both"
                      ? "/projects/build-your-first-website"
                      : "/projects/build-a-linear-regression-model";
                  localStorage.setItem(
                    "projectRecommendation",
                    projectRecommendation
                  );
                  pushOnboarding(authUser.uid, "experience", "Not really");
                  addOnboardingCount();
                }}
              >
                Not really
              </Next>
              <br />
              <Next
                paintDrip
                to={"/onboarding/project-start"}
                hex="#438cee"
                duration={1}
                onClick={() => {
                  let projectRecommendation =
                    interest == "Web Development" || interest == "Both"
                      ? "/projects/build-your-first-website"
                      : "/projects/build-a-linear-regression-model";
                  localStorage.setItem(
                    "projectRecommendation",
                    projectRecommendation
                  );
                  pushOnboarding(authUser.uid, "experience", "I've played around");
                  addOnboardingCount();
                }}
              >
                I've played around
              </Next>
              <br />
              <Next
                paintDrip
                to={
                  interest == "Web Development" || interest == "Both"
                    ? "/onboarding/web-development-recommendations-i"
                    : "/onboarding/machine-learning-recommendations-i"
                }
                hex="#438cee"
                duration={1}
                onClick={() => {
                  pushOnboarding(authUser.uid, "experience", "I've built several projects");
                  addOnboardingCount();
                }}
              >
                I've built several projects
              </Next>
              <br />
              <Next
                paintDrip
                to={
                  interest == "Web Development" || interest == "Both"
                    ? "/onboarding/web-development-recommendations-ii"
                    : "/onboarding/machine-learning-recommendations-ii"
                }
                hex="#438cee"
                duration={1}
                onClick={() => {
                  pushOnboarding(authUser.uid, "experience", "I'm a pro");
                  addOnboardingCount();
                }}
              >
                I'm a pro
              </Next>
            </div>
          </section>
        </Container>
      )}
    </AuthUserContext.Consumer>
  );
}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(ExperienceOnboarding);
